const pricacyIframes = {
    init() {
        const buttons= [...document.querySelectorAll('[data-privacy-button]')];
        

        // const iframes = [...document.querySelectorAll('iframe[data-src]')];


        if (buttons) {
            buttons.forEach((button) => {
                const container = button.closest('.m-privacy-iframe');
                const iframe = container.querySelectorAll('iframe[data-src]')[0];

                button.onclick = ()=> {
                    iframe.setAttribute('src', iframe.getAttribute('data-src'));
                    container.classList.add('has-privacy-accepted');
                };
            });
        }
    }
};

export default pricacyIframes;
