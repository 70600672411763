/*
 * Description of what this file is for
 *
 * @package  rebris_theme
 * @author Danny Stark [hello@dannystark.de]
 * toggle
 */
/* eslint-disable */
const toggle = {
    cfg:  {
        name: 'toggle',
        selectors: {
            toggle: '.js-toggle',
            closers: '.o-pageContent, .o-pageFooter, .o-clickToAction'
        }
    },

    init() {
        const selectors = document.querySelectorAll(this.cfg.selectors.toggle);
        const closers = document.querySelectorAll(this.cfg.selectors.closers);
        window.onload = function() {
            selectors.addEventListener('click',function () {
                document.body.classList.toggle('is-overlayOpen')

                closers.addEventListener('click',function (event) {
                    document.body.classList.remove('is-overlayOpen')
                })
            })
        }
    }
};

export default toggle;
