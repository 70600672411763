/**
 * Scripts
 */

import bodyClass from '../scripts/bodyclass';
// import barba from '../scripts/barba';
// import disableHover from '../scripts/disableHover';
// import browserDetect from '../scripts/bowser';
// import resizeClass from '../scripts/resizeClass';
import '../scripts/lazyloading';
import '../scripts/fontfaceobserver';
import privacyIframe from '../scripts/privacyIframe';
import '../scripts/pagetransition';
// import '../scripts/smoothScroll'
// import '../scripts/waypoints.anime'


// Modules Array
const scriptsArray = [
    bodyClass,
    // barba,
    privacyIframe,
    // disableHover,
    // browserDetect,
    // resizeClass
];

const scripts = {
    // Scripts
    Scripts() {
        if (scriptsArray.length > 0 && scriptsArray !== undefined) {
            scriptsArray.forEach((script) => {
                script.init();
            });
        }
    },

    init() {
        this.Scripts();
    }
};

export default scripts;
